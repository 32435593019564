@import url('https://fonts.googleapis.com/css?family=Roboto:300,400');

.float-left {
    float: left;
    width: 300px;
}

body {
    background-size: cover;
    background-color: gray;
}

.contenido {
    margin: 10px auto;
    max-height: 560px;
    max-width: 300px;
    overflow: hidden;
    box-shadow: 0 0 10px rgb(202, 202, 204);
    /* background-color:; */
    border-radius: 5px;
}

.details:after {
    /* background: linear-gradient(-45deg, #ffffff 16px, transparent 0), linear-gradient(45deg, #ffffff 16px, transparent 0);
    background-position: left-bottom;
    background-repeat: repeat-x;
    background-size: 32px 32px;
    content: " ";
    display: block;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 257px;
    height: 32px;
    margin-left: 58.5px;
    margin-top: 40px; */
}

.details {
    padding: 26px;
    background: white;
    border-bottom: 1px dashed #c3c3c3;
    border-top: 1px das #c3c3c3;

}

.tinfo {
    font-size: 0.5em;
    font-weight: 300;
    color: #6d6d6d;
    font-family: 'Roboto', sans-serif;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin: 7px 0;
}

.tdata {
    font-size: 0.7em;
    font-weight: 400;
    font-family: 'Roboto', sans-serif;
    letter-spacing: 0.5px;
    margin: 7px 0;
}

.name {
    font-size: 1.3em;
    font-weight: 300;
}

.link {
    text-align: center;
    margin-top: 10px;
}

a {
    text-decoration: none;
    color: #55C9E6;
    font-weight: 400;
    font-family: 'Roboto', sans-serif;
    letter-spacing: 0.7px;
    font-size: 0.7em;
}

.hqr {
    display: table;
    width: 100%;
    table-layout: fixed;
    margin: 0px auto;
}

.left-one {
    background-repeat: no-repeat;
    background-image: radial-gradient(circle at 0 7%, rgba(0, 0, 0, 0) .2em, gray .3em, white .1em);
    width: 5%;
}
.left-one-top{
    background-repeat: no-repeat;
    background-image:radial-gradient(circle at 0 7%, rgba(0, 0, 0, 0) .76em, gray .7em, white .1em);
    width: 5%
}

.right-one {
    background-repeat: no-repeat;
    background-image: radial-gradient(circle at 100% 7%, rgba(0, 0, 0, 0) .2em, gray .3em, white .1em);
    width: 5%;
}
.right-one-top {
    background-repeat: no-repeat;
    background-image: radial-gradient(circle at 100% 7%, rgba(0, 0, 0, 0) .76em, gray .7em, white .1em);
    width: 5%;
}

.column {
    display: table-cell;
    /* padding: 10px 0px; */
}

.center {
    background: white;
    
}

#qrcode img {
    height: 70px;
    width: 70px;
    margin: 0 auto;
}

.masinfo {
    display: block;
}

.left,
.right {
    width: 49%;
    display: inline-table;
}

.nesp {
    letter-spacing: 0px;
}